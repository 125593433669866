<template>
  <Toast />
  <div style="overflow:auto;" class="bg-white rounded-2xl mt-5">
    <div class="h-full p-3" style="min-width: 1400px">
      <!--  inicio filtros    -->
      <div class="grid grid-cols-3 gap-4">
        <div class="flex">
          <Button class="p-button-danger p-button-label"
                  icon="pi pi-arrow-left"
                  v-tooltip.bottom="{value: 'Atras'}"
                  @click="$router.back()"
          />
          <Button class="p-button ml-2 p-button-label"
                  icon="pi pi-plus-circle"
                  v-tooltip.bottom="{value: 'Agregar nuevo socio'}"
                  @click="addSocio()"
          />
          <Button class="p-button-success ml-2 p-button-label"
                  icon="pi pi-save"
                  v-tooltip.bottom="{value: `${isUpdated ? 'Actualizar' : 'Guardar'}`}"
                  @click="onCreateOrUpdate()"
          />
        </div>
        <div class="licitacion-select">
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-list"/>
            <p class="ml-1">
              Licitaciones
            </p>
          </span>
            <Dropdown v-model="selectLicitacionId"
                      :options="optionsLicitaciones"
                      optionLabel="nombre"
                      optionValue="id"
                      placeholder=""
                      @change="onChangeLicitacion"
                      :filter="true"
            />
          </div>
        </div>
        <div class="presupuesto">
          <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
              <i class="pi pi-dollar"/>
              <p class="ml-1">
                Presupuesto
              </p>
          </span>
            <InputText v-model="vlrPresupuesto"
                       placeholder="Presupuesto"
            />
          </div>
        </div>
      </div>
      <!--  parametros    -->
      <div  v-show="selectLicitacionId" class="mt-5 flex gap-4">
        <div class="indicadors w-3/4 box-gray-150 mt-1 p-2 rounded">
          <div class="grid gap-4" :class="`grid-cols-${(socios.length)+2}`">
            <div class="flex justify-center items-center col-span-2">
              <span class="font-bold">
                Año Fiscal
              </span>
            </div>
            <div class="socios-negocio" v-for="(data) in socios" :key="data">
              <div class="flex flex-col">
                <span class="text-center font-bold">
                  {{ data.nombre }}
                </span>
                <div class="flex">
                  <div class="p-field w-1/2">
                    <InputNumber :id="`input-number-${data.nit}`"
                                 class="p-inputtext-sm"
                                 prefix="%"
                                 v-model="data.porcentaje"
                                 mode="decimal"
                                 :min="0"
                                 :max="100"
                                 showButtons
                    />
                  </div>
                  <div class="p-field w-1/2">
                    <Dropdown v-model="data.year"
                              :options="yearList"
                              placeholder="año"
                              @change="onchangeSocioYear(data)"
                              class="p-inputtext-sm w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid gap-4" :class="`grid-cols-${(socios.length)+2}`">
            <div class="parametros-participacion col-span-2">
              <DataTable :value="parametrosParticipacion"
                         responsiveLayout="scroll"
                         class="p-datatable-sm"
              >
                <Column field="nombre" header="nombre" bodyClass="flex items-center h-xxl"  >
                  <template #body="{ data }">
                    {{data.nombre}}
                  </template>
                </Column>
              </DataTable>
            </div>
            <div class="parametros-participacion-vlr" v-for="(socio) in socios" :key="socio">
              <DataTable :value="socio.parametrosParticipacion"
                         class="p-datatable-sm"
                         responsiveLayout="scroll"
              >
                <Column field="vlr" header="valor" bodyClass="h-xxl flex justify-end items-center">
                  <template #body="{ data }">
                    {{ $h.formatCurrency(data.vlr, 2) }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div class="result w-1/4 box-gray-150 mt-1 p-2 rounded">
          <div class="grid gap-4">
            <div class="flex justify-center items-center" style="padding: 1.65rem;">
              <span class="font-bold">
                Estados Financieros
              </span>
            </div>
          </div>
          <div class="result-participacion">
            <DataTable :value="resultParticipacion"
                       responsiveLayout="scroll"
                       class="p-datatable-sm"
            >
              <Column field="vlr" header="valor" bodyClass="h-xxl flex justify-end items-center">
                <template #body="{ data }">
                  {{ $h.formatCurrency(data.vlr, 2) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <!--  indicadores    -->
      <div v-show="selectLicitacionId" class="flex gap-4">
        <div class="indicadors w-3/4 box-gray-150 mt-1 p-2 rounded">
          <div class="grid gap-4" :class="`grid-cols-${(socios.length)+2}`">
            <div class="flex justify-center items-center p-2.5 col-span-2">
              <span class="font-bold">
                Indicadores
              </span>
            </div>
            <div class="socios-negocio" v-for="(data) in socios" :key="data">
              <div class="flex justify-center items-center p-2.5">
                <span class="font-bold">
                  Resultado
                </span>
              </div>
            </div>
          </div>
          <div class="grid gap-4" :class="`grid-cols-${(socios.length)+2}`">
            <div class="parametros-participacion col-span-2">
              <DataTable :value="indicadoresTypes"
                         class="p-datatable-sm"
                         responsiveLayout="scroll"
                         showGridlines
              >
                <Column field="nombre" header="Indicador" bodyClass="flex items-center h-xxl" header-class="p-flex" header-style="justify-content: center;">
                  <template #body="{ data }">
                    {{data.nombre}}
                  </template>
                </Column>
                <Column field="operation" header="Indice">
                  <template #body="{ data }">
                    <Dropdown v-model="data['operation']"
                              :options="indiceList"
                              option-label="name"
                              option-value="vlr"
                              placeholder="Seleccione"
                              class="p-inputtext-sm w-full"
                              @blur="calcularIndicadores()"
                    />
                  </template>
                </Column>
                <Column field="vlr" header="valor">
                  <template #body="{ data }">
                    <InputNumber class="p-inputtext-sm w-full"
                                 v-model="data['vlr']"
                                 autofocus
                                 mode="decimal"
                                 :minFractionDigits="2"
                                 @blur="calcularIndicadores()"
                    />
                  </template>
                </Column>
              </DataTable>
            </div>
            <div class="parametros-participacion-vlr" v-for="(socio) in socios" :key="socio">
              <DataTable :value="socio.indicadores"
                         class="p-datatable-sm"
                         responsiveLayout="scroll"
              >
                <Column header="Valor" bodyClass="flex items-center h-xxl" >
                  <template #body="{ data }">
                    <p :v-tooltip="`${data.valor}`">
                      {{data.message}} - {{data.valor}}
                    </p>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div class="result w-1/4 box-gray-150 mt-1 p-2 rounded">
          <div class="flex justify-center items-center p-2.5">
            <span class="font-bold">
              Resultado Final
            </span>
          </div>
          <div class="result-participacion">
            <DataTable :value="resultIndicadores"
                       responsiveLayout="scroll"
                       class="p-datatable-sm"
            >
              <Column header="Valor" bodyClass="flex items-center h-xxl" >
                <template #body="{ data }">
                  <p :v-tooltip="`${data.valor}`">
                    {{data.message}} - {{data.valor}}
                  </p>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <!--   Modals   -->
      <NoData v-show="!selectLicitacionId" :text="'Seleccione una licitacion '"/>
      <Dialog header="Agregar socio de negocio" v-model:visible="displayModalSocios" style="width: 480px" >
        <div class="p-inputgroup mb-3">
            <span class="p-inputgroup-addon">
                Socios de negocio
            </span>
            <Dropdown v-model="selectedListSocios"
                      :options="optionslistSocios"
                      optionLabel="nombre"
                      option-value="nit"
                      :filter="true"
                      placeholder="Seleccione"
                      :showClear="true"/>
        </div>
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                Año fiscal
            </span>
          <Dropdown v-model="selectedSocioYear"
                    :options="yearList"
                    placeholder="año"
                    class="mb-2"
          />
        </div>
        <template #footer>
          <div class="w-full">
            <button class="p-button p-button-secondary" @click="displayModalSocios = false">
              Cancelar
            </button>
            <button class="p-button p-button-primary" @click="addNewSocio()">
              Agregar
            </button>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>
<script>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import LicIndicadoresService from '../../services/LicIndicadoresService'
import SociosService from '../../services/SociosService'
import LicitacionesService from '../../services/LicitacionesService'
import LicIndicadoresValores from '../../services/LicIndicadoresValores'
import LicIndicadoresResultadosService from '../../services/LicIndicadoresResultadosService'
import { useRoute } from 'vue-router'
import { funcion } from '../../../../../utils/funciones'
import { useToast } from 'primevue/usetoast'
const lodash = require('lodash')
export default {
  name: 'Indicadores',
  components: {
    NoData: defineAsyncComponent(() => import('./noData'))
  },
  setup () {
    /** instancias **/
    const _LicIndicadoresService = ref(new LicIndicadoresService())
    const _SociosService = ref(new SociosService())
    const _LicitacionesService = ref(new LicitacionesService())
    const _LicIndicadoresValores = ref(new LicIndicadoresValores())
    const _LicIndicadoresResultadosService = ref(new LicIndicadoresResultadosService())
    const router = useRoute()
    const toast = useToast()
    /** data **/
    const vlrPresupuesto = ref(0)
    const socios = ref([])
    const displayModalSocios = ref(false)
    /** options **/
    const optionsLicitaciones = ref([])
    const indicadoresTypes = ref([])
    const parametrosParticipacion = ref([])
    const listaSocios = ref([])
    /** select **/
    const selectLicitacionId = ref(null)
    const selectedListSocios = ref(null)
    const selectedSocioYear = ref(null)
    const isUpdated = ref(false)
    /** computed **/
    const yearList = computed(() => {
      const years = []
      const limit = new Date().getFullYear()
      for (let i = 2017; i <= limit; i++) {
        years.push(i)
      }
      return years.reverse()
    })
    const indiceList = computed(() => {
      return [
        { name: 'Igual', vlr: '===' },
        { name: 'Menor', vlr: '<' },
        { name: 'Menor y igual', vlr: '<=' },
        { name: 'Mayor', vlr: '>' },
        { name: 'Mayor  y igual', vlr: '>=' }
      ]
    })
    const resultParticipacion = computed(() => {
      let res = []
      const copy = socios.value.slice()
      for (const socio of Object.values(copy)) {
        for (const parametro of Object.values(socio.parametrosParticipacion)) {
          const vlr = (parametro.vlr * socio.porcentaje) / 100
          res.push({
            id: parametro.id,
            vlr: vlr,
            code: parametro.code,
            ano: parametro.ano
          })
        }
      }
      res = res.flat()
      res = lodash.map(lodash.groupBy(res, 'code'), (value, key) => {
        return {
          id: key,
          code: value[0].code,
          vlr: value.reduce((acc, cur) => acc + cur.vlr, 0)
        }
      })
      return res
    })
    const resultIndicadores = computed(() => {
      const obj = {}
      for (const value of Object.values(resultParticipacion.value)) {
        obj[value.code] = value.vlr
      }
      const { ACT_C, ACT_T, GIN, PAS_C, PAS_T, PAT, UOP } = obj
      const presupuesto = vlrPresupuesto.value
      const response = []
      for (const indicador of Object.values(indicadoresTypes.value)) {
        const INDICADOR = {
          operation: indicador.operation ?? '===',
          valor: indicador.vlr ?? 0
        }
        switch (indicador.code) {
          case 'LIQ':
            response.push(funcion.liquides(ACT_C, PAS_C, INDICADOR))
            break
          case 'END':
            response.push(funcion.endeudamiento(PAS_T, ACT_T, INDICADOR))
            break
          case 'PAT':
            response.push(funcion.patrimonio(presupuesto, indicador.operation, indicador.vlr, PAT))
            break
          case 'CAP':
            response.push(funcion.capitalTrabajo(ACT_C, PAS_C, indicador.operation, presupuesto, indicador.vlr))
            break
          case 'RPA':
            response.push(funcion.rentabilidadPatrimonio(UOP, PAT, INDICADOR))
            break
          case 'RAC':
            response.push(funcion.rentabilidadActivo(UOP, ACT_T, INDICADOR))
            break
          case 'RZO':
            response.push(funcion.razonCoberturaInteres(UOP, GIN, INDICADOR))
            break
        }
      }
      return response
    })
    const optionslistSocios = computed(() => {
      // return listaSocios.value.filter(socio => {
      //   if (!socios.value.some(s => s.id === socio.id)) {
      //     return socio
      //   }
      // })
      return listaSocios.value
    })
    /** methodos **/
    const getIndicadoresTypes = () => {
      return _LicIndicadoresService.value.getIndicadoresTypes().then(({ data }) => {
        indicadoresTypes.value = data.map(x => {
          return {
            ...x,
            operation: '===',
            vlr: 0
          }
        })
        return true
      })
    }
    const getParametrosParticipacion = () => {
      return _LicIndicadoresService.value.getParametrosParticipacion().then(({ data }) => {
        parametrosParticipacion.value = data
        return parametrosParticipacion.value
      })
    }
    const calcularIndicadores = () => {
      for (const socio of Object.values(socios.value)) {
        const response = []
        const { vlr: ACT_C } = socio.parametrosParticipacion.find(x => x.code === 'ACT_C')
        const { vlr: ACT_T } = socio.parametrosParticipacion.find(x => x.code === 'ACT_T')
        const { vlr: PAS_C } = socio.parametrosParticipacion.find(x => x.code === 'PAS_C')
        const { vlr: PAS_T } = socio.parametrosParticipacion.find(x => x.code === 'PAS_T')
        const { vlr: PAT } = socio.parametrosParticipacion.find(x => x.code === 'PAT')
        const { vlr: UOP } = socio.parametrosParticipacion.find(x => x.code === 'UOP')
        const { vlr: GIN } = socio.parametrosParticipacion.find(x => x.code === 'GIN')
        const presupuesto = vlrPresupuesto.value
        for (const indicador of Object.values(indicadoresTypes.value)) {
          const INDICADOR = {
            operation: indicador.operation ?? '===',
            valor: indicador.vlr ?? 0
          }
          switch (indicador.code) {
            case 'LIQ':
              response.push(funcion.liquides(ACT_C, PAS_C, INDICADOR))
              break
            case 'END':
              response.push(funcion.endeudamiento(PAS_T, ACT_T, INDICADOR))
              break
            case 'PAT':
              response.push(funcion.patrimonio(presupuesto, indicador.operation, indicador.vlr, PAT))
              break
            case 'CAP':
              response.push(funcion.capitalTrabajo(ACT_C, PAS_C, indicador.operation, presupuesto, indicador.vlr))
              break
            case 'RPA':
              response.push(funcion.rentabilidadPatrimonio(UOP, PAT, INDICADOR))
              break
            case 'RAC':
              response.push(funcion.rentabilidadActivo(UOP, ACT_T, INDICADOR))
              break
            case 'RZO':
              response.push(funcion.razonCoberturaInteres(UOP, GIN, INDICADOR))
              break
          }
        }
        socio.indicadores = response
      }
    }
    const addSocio = () => {
      displayModalSocios.value = true
    }
    const addFirtsSocio = () => {
      return _SociosService.value.getWithNit().then(({ data }) => {
        return data
      })
    }
    const getSocios = () => {
      return _SociosService.value.getAll().then(({ data }) => {
        listaSocios.value = data
        return true
      })
    }
    const onchangeSocioYear = (value) => {
      // alert('onchangeSocioYear')
      // console.log(value)
    }
    const addNewSocio = () => {
      if (!(selectedListSocios.value && selectedSocioYear.value)) {
        toast.add({
          severity: 'warn',
          summary: 'Advertencia',
          detail: 'seleccione un socio y un año',
          life: 3000
        })
      }
      _SociosService.value.getWithNit(selectedListSocios.value, selectedSocioYear.value).then(({ data }) => {
        if (!data) {
          toast.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: `No se encontro estados financieros para este socio en el ${selectedSocioYear.value}`,
            life: 3000
          })
        } else {
          // console.log(data)
          socios.value.push({
            id: data.id,
            nit: data.nit,
            nombre: data.nombre,
            porcentaje: 0,
            year: selectedSocioYear.value,
            indicadores: [],
            parametrosParticipacion: data.estadosFinancieros
          })
          setTimeout(() => {
            document.getElementById(`input-number-${data.nit}`).style.width = '100%'
          }, 0)
          calcularIndicadores()
          displayModalSocios.value = false
        }
      })
    }
    const getLicitaciones = () => {
      _LicitacionesService.value.getAllLicitaciones().then(({ data }) => {
        optionsLicitaciones.value = data
      })
      return true
    }
    const onChangeLicitacion = ({ value: licitacionId }) => {
      const { valorPresupuesto } = optionsLicitaciones.value.find(x => x.id === licitacionId)
      vlrPresupuesto.value = valorPresupuesto
      _LicIndicadoresValores.value.get({ licitacionId }).then(({ data }) => {
        indicadoresTypes.value = indicadoresTypes.value.map(x => {
          const find = data.find(e => e.code === x.code)
          if (find) {
            x.operation = find.operation
            x.vlr = find.valor
          }
          return x
        })
      })
     setTimeout(() => {
       calcularIndicadores()
     }, 1000)
    }
    const onCreateOrUpdate = () => {
      const data = {
        licitacionId: selectLicitacionId.value,
        socios: socios.value
      }
      _LicIndicadoresResultadosService.value.createOrUpdate(data).then(({ data }) => {
        toast.add({
          severity: 'success',
          summary: 'Exito',
          detail: 'Se guardo correctamente',
          life: 3000
        })
      })
    }
    const getIndicadoresResultados = () => {
      return _LicIndicadoresResultadosService.value.get(router.params.id).then(({ data }) => {
        return data
      })
    }
    /** hooks **/
    onMounted(async () => {
      if (router.params.id) {
        const data = await getIndicadoresResultados().then(data => data)
        if (!data) {
          isUpdated.value = false
          Promise.all([
            getIndicadoresTypes(),
            getParametrosParticipacion(),
            getSocios(),
            addFirtsSocio(),
            getLicitaciones()
          ]).then((p) => {
            socios.value.push({
              id: p[3].id,
              nit: p[3].nit,
              nombre: p[3].nombre,
              year: p[3].estadosFinancieros[0].ano,
              porcentaje: 100,
              indicadores: [],
              parametrosParticipacion: p[3].estadosFinancieros
            })
            calcularIndicadores()
            selectLicitacionId.value = parseInt(router.params.id)
            onChangeLicitacion({ value: parseInt(router.params.id) })
            setTimeout(() => {
              document.querySelectorAll('[id^="input-number-"]').forEach(x => {
                x.style.width = '100%'
              })
            }, 0)
          })
        } else {
          isUpdated.value = true
          Promise.all([
            getIndicadoresTypes(),
            getParametrosParticipacion(),
            getSocios(),
            getLicitaciones()
          ]).then(() => {
            socios.value = data.socios
            selectLicitacionId.value = data.licitacionId
            onChangeLicitacion({ value: data.licitacionId })
            setTimeout(() => {
              calcularIndicadores()
              document.querySelectorAll('[id^="input-number-"]').forEach(x => {
                x.style.width = '100%'
              })
            }, 0)
          })
        }
      } else {
        Promise.all([
          getIndicadoresTypes(),
          getParametrosParticipacion(),
          getSocios(),
          addFirtsSocio(),
          getLicitaciones()
        ]).then((p) => {
          socios.value.push({
            id: p[3].id,
            nit: p[3].nit,
            nombre: p[3].nombre,
            year: p[3].estadosFinancieros[0].ano,
            porcentaje: 100,
            indicadores: [],
            parametrosParticipacion: p[3].estadosFinancieros
          })
          calcularIndicadores()
          setTimeout(() => {
            document.getElementById('input-number-900249425').style.width = '100%'
          }, 0)
        })
      }
    })
    return {
      vlrPresupuesto,
      selectLicitacionId,
      optionsLicitaciones,
      socios,
      parametrosParticipacion,
      resultParticipacion,
      indicadoresTypes,
      resultIndicadores,
      yearList,
      indiceList,
      calcularIndicadores,
      addSocio,
      listaSocios,
      onchangeSocioYear,
      optionslistSocios,
      selectedListSocios,
      displayModalSocios,
      selectedSocioYear,
      addNewSocio,
      onChangeLicitacion,
      onCreateOrUpdate,
      isUpdated
    }
  }
}
</script>
<style lang="scss" scoped>
.box-gray-150 {
  background: #e9ecef;
  color: #6c757d;
}
::v-deep(.p-datatable .p-column-header-content){
    justify-content: center;
}
.j-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0.375rem;
}
select {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0.375rem;
}
</style>
