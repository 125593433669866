import http from '@/libs/http'
export default class LicIndicadoresResultadosService {
  createOrUpdate (data) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indicadores-resultados`, data, {
      headers: {
        loading: true
      }
    })
  }

  get (licitacionId) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones-indicadores-resultados/${licitacionId}`, {})
  }
}
